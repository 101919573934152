// @ts-ignore
import IPFS from "ipfs";
// import all from "it-all"

const DATA_PATH = "/rikiki";
const MANIFEST_PATH = `${DATA_PATH}/games.json`;


async function chunksToStr(it: Promise<Iterable<Uint8Array>>): Promise<string> {
    const chunks: Array<Uint8Array> = [];
    for await (const chunk of await it) {
        chunks.push(chunk);
    }
    return Buffer.concat(chunks).toString();
}

export type GameId = string
export type IpfsCid = string
export type Game = { id: GameId, cid: IpfsCid }
export type Manifest = object;
export type AllGames = Array<Game>;

/**
 * Persist game data to places
 */
export abstract class Persistence {
    /**
     * Async setup
     * @see https://stackoverflow.com/questions/35743426/async-constructor-functions-in-typescript
     */
    abstract init(): Promise<void>;

    abstract getManifest(): Promise<Manifest>;

    abstract getGame(cid: IpfsCid): Promise<Game>;

    abstract writeGame(game: Game): Promise<IpfsCid>;

    abstract writeManifest(data: Manifest): Promise<void>;

    async getKnownGames(): Promise<AllGames> {
        let manifest = await this.getManifest();
        const results: AllGames = [];
        // TODO: make this loading concurrent again...
        for (const cid of Object.values(manifest)) {
            try {
                const game = await this.getGame(cid);
                results.push(game);
            } catch (e) {
                console.warn("Couldn't load game CID=%s", cid);
            }
        }
        return results
    }

    async addGame(cid: string): Promise<Game> {
        const newGame = await this.getGame(cid);
        const manifest = await this.getManifest();
        manifest[newGame.id] = newGame;
        console.info("Writing updated manifest with %d game(s)...", Object.keys(manifest).length);
        await this.writeManifest(manifest);
        return newGame;
    }

    async deleteGame(gameId: GameId): Promise<void> {
        const manifest = await this.getManifest();
        console.info("Deleting gameID", gameId)
        delete manifest[gameId];
        return this.writeManifest(manifest);
    }
}

/**
 * IPFS-based persistence.
 * Saves and load a "local" manifest,
 * but each game object is at a CID of its own (depending on content of course).
 *
 * The manifest is updated whenever a game is touched,
 * to make sure a "current view" is always maintained (locally at least)
 */
export class IpfsPersistence extends Persistence {

    constructor(private ipfs: IPFS) {
        super();
    }

    async init() {
        console.info("Starting IPFS");

        try {
            await this.getManifest();
        } catch (e) {
            console.warn("Couldn't find manifest (%s)", e);
            throw("Failed to find IPFS store");
        }
        console.info("Started IPFS", this.ipfs);
    }

    async writeGame(game: Game): Promise<IpfsCid> {
        const str = JSON.stringify(game);
        const result = (await this.ipfs.add(str, {cidVersion: 1}).next()).value;
        console.info("Now stored game %s at https://ipfs.io/ipfs/%s", game.id, result.path);
        return result.path;
    }

    async writeManifest(manifest: Manifest) {
        console.info("Saving all %d game(s) to IPFS", Object.keys(manifest).length, manifest);
        await this.ipfs.files.write(
            MANIFEST_PATH,
            Buffer.from(JSON.stringify(manifest)),
            {create: true, parents: true, flush: true, truncate: true});
        console.log("Wrote IPFS data to %s", MANIFEST_PATH);
    }

    async getManifest(): Promise<Manifest> {
        const str = await chunksToStr(this.ipfs.files.read(MANIFEST_PATH));
        if (!str) {
            throw `No IPFS Manifest found at ${MANIFEST_PATH}`;
        }
        return JSON.parse(str);
    }

    async getGame(cid: IpfsCid): Promise<Game> {
        console.info("Fetching CID", cid);
        const gameStr = await chunksToStr(this.ipfs.cat(cid));
        const game = JSON.parse(gameStr);
        // Update in-memory version with latest cid, to allow Elm to reference this.
        game.cid = cid;
        return game;
    }

}


export class LocalPersistence extends Persistence {
    async init(): Promise<void> {
        console.debug("Local storage ready");
    }

    async getManifest(): Promise<Manifest> {
        const data = localStorage.getItem(MANIFEST_PATH);
        try {
            const manifest = JSON.parse(data || "{}");
            console.info("Got local manifest @ '%s'", MANIFEST_PATH, manifest);
            return manifest
        } catch (e) {
            console.error(e);
            return new Map();
        }
    }

    async writeManifest(manifest: Manifest): Promise<void> {
        await localStorage.setItem(MANIFEST_PATH, JSON.stringify(manifest));
        console.info("Saved manifest to local storage")
    }

    async getKnownGames(): Promise<AllGames> {
        return [];
    }

    async writeGame(game: Game): Promise<IpfsCid> {
        localStorage.setItem(`${DATA_PATH}/${game.cid}`, JSON.stringify(game));
        // TODO: what to return here?
        return "UNKNOWN"
    }

    addGame(cid: string): Promise<Game> {
        return Promise.reject("Can't do this locally");
    }

    async getGame(cid: IpfsCid): Promise<Game> {
        const rawLocal = localStorage.getItem(`${DATA_PATH}/${cid}`);
        if (!rawLocal) {
            return Promise.reject("Local data for CID %s not found");
        }
        return JSON.parse(rawLocal || "{}");
    }

}

/**
 * Falls back (and backs up) to Local Storage when IPFS
 */
export class IpfsThenLocalPersistence extends Persistence {
    constructor(private ipfs: IpfsPersistence, private local: LocalPersistence) {
        super();
    }

    async init(): Promise<void> {
        await this.local.init();
        try {
            await this.ipfs.init();
        } catch (e) {
            console.warn("IPFS store not found. Migrating content from local storage...");
            const localManifest = await this.local.getManifest();
            await this.ipfs.writeManifest(localManifest);
        }
    }

    async getManifest(): Promise<Manifest> {
        try {
            return this.ipfs.getManifest();
        } catch (e) {
            console.warn("Failed to get manifest from IPFS (%s), trying local...", e)
            let localManifest = await this.local.getManifest();
            if (localManifest) {
                await this.ipfs.writeManifest(localManifest);
            }
            return localManifest;
        }
    }

    async getKnownGames(): Promise<AllGames> {
        try {
            return await this.ipfs.getKnownGames();
        } catch (e) {
            console.warn("Failed to get all games from IPFS (%s), trying local...", e)
            return await this.local.getKnownGames();
        }
    }

    async writeGame(game: Game): Promise<IpfsCid> {
        try {
            return await this.ipfs.writeGame(game);
        } catch (e) {
            console.log("Failed writing to IPFS (%s)", e)
            return await this.local.writeGame(game);
        }
    }

    async writeManifest(manifest: Manifest): Promise<void> {
        await this.local.writeManifest(manifest);
        return await this.ipfs.writeManifest(manifest);
    }

    async deleteGame(gameId: GameId): Promise<void> {
        await this.ipfs.deleteGame(gameId);
        await this.local.deleteGame(gameId);
    }

    async getGame(cid: IpfsCid): Promise<Game> {
        try {
            return (await this.ipfs.getGame(cid));
        } catch (e) {
            console.info("Falling back to local game for CID %s", cid)
            return (await this.local.getGame(cid));
        }
    }
}
