import 'core-js/stable';
// @ts-ignore
import IPFS from "ipfs";
// @ts-ignore
import {Elm} from "./elm/Main.elm";

import {ElmApp, FromElm, ToElm} from "./Elm";
import {
    AllGames,
    Game,
    GameId,
    IpfsCid,
    IpfsPersistence,
    IpfsThenLocalPersistence,
    LocalPersistence,
    Persistence
} from "./Persistence";

/**
 * This is fixed (no randomness),
 * as we _want_ storage-like properties over time.
 */
const IPFS_NODE_KEY = "ipfs-rikiki-v2.0";

let app: ElmApp;

class Connections {
    constructor(public ipfs: { version: string, nodes: [string], bytesIn: number, bytesOut: number }) {
    }
}

/**
 * TS view on our Elm app
 */
export interface Ports {
    saveGame: FromElm<Game>
    loadGame: FromElm<IpfsCid>
    deleteGame: FromElm<GameId>
    sendLog: FromElm<string>

    gameLoaded: ToElm<Game>
    allGamesLoaded: ToElm<AllGames>
    connectionsUpdated: ToElm<Connections>
}


async function initApp(persistence: Persistence): Promise<ElmApp> {
    // @ts-ignore
    const crypto = window.crypto || window.msCrypto;
    await persistence.init();

    const getRandomInts = (n: number) => {
        const randInts = new Uint32Array(n);
        crypto.getRandomValues(randInts);
        return Array.from(randInts);
    };

    // For a UUID, we need at least 128 bits of randomness.
    // This means we need to seed our generator with at least 4 32-bit ints.
    // We get 5 here, since the Pcg.Extended generator performs slightly faster if our extension array
    // has a size that is a power of two (4 here).
    const randInts = getRandomInts(5);
    const flags = {
        currentTime: new Date().getTime(),
        data: await persistence.getKnownGames(),
        seed: randInts[0],
        seedExtension: randInts.slice(1)
    }

    app = Elm.Main.init({flags: flags});

    // Subscribe to ports
    app.ports.saveGame.subscribe(async game => {
        const cid = await persistence.writeGame(game);
        // We *must* update the game to the new CID before sending back
        game.cid = cid;
        const manifest = await persistence.getManifest();
        manifest[game.id] = cid;
        await persistence.writeManifest(manifest);
        app.ports.gameLoaded.send(game);
    });

    app.ports.sendLog.subscribe(msg => {
        console.log(msg);
    });

    app.ports.loadGame.subscribe(async cid => {
        const newGame = await persistence.addGame(cid);
        app.ports.gameLoaded.send(newGame);
    });

    app.ports.deleteGame.subscribe(async gameId => {
        await persistence.deleteGame(gameId);
    })
    return app;
}

async function getConData(ipfs: IPFS): Promise<Connections> {
    let version = (await ipfs.version()).version;
    let stats = (await ipfs.stats.bw().next()).value;
    const nodes = (await ipfs.bootstrap.list()).Peers;
    return new Connections({
        version: version,
        nodes: nodes,
        bytesIn: stats.totalIn.toNumber(),
        bytesOut: stats.totalOut.toNumber()
    });
}

async function sendConnectionData(ipfs: IPFS): Promise<void> {
    const connections = await getConData(ipfs);
    app.ports.connectionsUpdated.send(connections);
}


document.addEventListener('DOMContentLoaded', async () => {

    const ipfs = await IPFS.create({
        repo: IPFS_NODE_KEY
    });
    // Debugging really
    // @ts-ignore
    document.ipfs = ipfs;

    // Set up persistence
    app = await initApp(new IpfsThenLocalPersistence(new IpfsPersistence(ipfs), new LocalPersistence()));

    // TODO: Move this flow to Elm perhaps
    await sendConnectionData(ipfs);
    setInterval(sendConnectionData, 5000, ipfs);
    console.info("Initialised App with ports", app.ports);
});
